import { t } from '@zupr/i18n'
import { aggregationKeys } from '@zupr/next/helpers/products'
import { AggregationBucket, Aggregations } from '@zupr/types/fo'
import { Field } from '@zupr/types/form'
import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo, useState } from 'react'
import InputRange, { Range } from 'react-input-range'
import '../../../../../scss/react/filter/histogram.scss'
import RouteContext from '../../../../context/route'
import FilterCollapse from '../../../../shared/filters/collapse'
import { CombinedFields } from '../../../../shared/form/elements/row'
import Input from '../../../../shared/form/fields/input'

interface FieldProps {
    step?: number
    header: string
    factor?: 1 | 10 | 100 | 1000
    unit?: '€' | '%'
    minValue: number
    maxValue: number
    value?: Range
    onChange: (range: Range) => void
}

const HistogramField = ({
    step,
    header,
    unit,
    factor = 1,
    minValue,
    maxValue,
    onChange,
    ...props
}: FieldProps) => {
    const [valueWhileEditing, setValue] = useState<Range>() // input range doesnt have local state

    const value: Range = useMemo(
        () => ({
            min: valueWhileEditing?.min || props.value?.min || minValue,
            max: valueWhileEditing?.max || props.value?.max || maxValue,
        }),
        [
            maxValue,
            minValue,
            props.value?.min,
            props.value?.max,
            valueWhileEditing,
        ]
    )

    const fieldMin = useMemo<Field>(() => {
        return {
            name: 'min',
            value:
                ((value && parseInt(`${value.min}`, 10)) || minValue) / factor,
            setValue: (value) => {
                setValue({ ...value, min: value * factor })
            },
        }
    }, [factor, minValue, value])

    const fieldMax = useMemo<Field>(() => {
        return {
            name: 'max',
            value:
                ((value && parseInt(`${value.max}`, 10)) || maxValue) / factor,
            setValue: (value) => {
                setValue({ ...value, max: value * factor })
            },
        }
    }, [factor, maxValue, value])

    const handleBlur = useCallback(() => onChange(value), [onChange, value])

    return (
        <FilterCollapse header={header}>
            <div className="histogram-filter">
                <div className="histogram-filter-inputs">
                    <CombinedFields>
                        {unit && (
                            <span className="before-input small">{unit}</span>
                        )}
                        <Input
                            className="small"
                            field={fieldMin}
                            onBlur={handleBlur}
                        />
                    </CombinedFields>
                    <span>{t('tot')}</span>
                    <CombinedFields>
                        {unit && (
                            <span className="before-input small">{unit}</span>
                        )}
                        <Input
                            className="small"
                            field={fieldMax}
                            onBlur={handleBlur}
                        />
                    </CombinedFields>
                </div>
                <InputRange
                    step={step}
                    minValue={minValue}
                    maxValue={maxValue}
                    value={value || { min: minValue, max: maxValue }}
                    onChange={(range: Range) => setValue(range)}
                    onChangeComplete={onChange}
                />
            </div>
        </FilterCollapse>
    )
}

interface HistogramMinMaxFilterProps
    extends Omit<FieldProps, 'onChange' | 'value' | 'minValue' | 'maxValue'> {
    aggregations: Aggregations
    minFilterKey: string
    maxFilterKey: string
    minAggregationKey: string
    maxAggregationKey: string
}

export const HistogramMinMax = ({
    aggregations,
    minFilterKey,
    maxFilterKey,
    minAggregationKey,
    maxAggregationKey,
    ...props
}: HistogramMinMaxFilterProps) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const value: Range = {
        min: query[minFilterKey]
            ? parseInt(`${query[minFilterKey]}`, 10)
            : undefined,
        max: query[maxFilterKey]
            ? parseInt(`${query[maxFilterKey]}`, 10)
            : undefined,
    }

    const minBuckets = useMemo<AggregationBucket[]>(() => {
        const filterBuckets = (aggregations, key) => {
            const filtered = aggregations?.filtered || aggregations
            return (
                filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0) ||
                []
            )
        }
        return filterBuckets(
            aggregations.data,
            aggregationKeys[minAggregationKey]
        )
    }, [aggregations.data, minAggregationKey])

    const minValue = minBuckets.reduce(
        (min, bucket) => Math.min(min, parseInt(`${bucket.key}`, 10)),
        1
    )

    const maxBuckets = useMemo<AggregationBucket[]>(() => {
        const filterBuckets = (aggregations, key) => {
            const filtered = aggregations?.filtered || aggregations
            return (
                filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0) ||
                []
            )
        }
        return filterBuckets(
            aggregations.data,
            aggregationKeys[maxAggregationKey]
        )
    }, [aggregations.data, maxAggregationKey])

    const maxValue = maxBuckets.reduce(
        (max, bucket) => Math.max(max, parseInt(`${bucket.key}`, 10)),
        1
    )

    const handleChange = useCallback(
        ({ min, max }) => {
            push(
                changeQuery({
                    [minFilterKey]: `${min}:${maxValue}`,
                    [maxFilterKey]: `${minValue}:${max}`,
                })
            )
        },
        [push, changeQuery, minFilterKey, maxValue, maxFilterKey, minValue]
    )

    if (!minBuckets.length || !maxBuckets.length) return null

    return (
        <HistogramField
            {...props}
            minValue={minValue}
            maxValue={maxValue}
            value={value}
            onChange={handleChange}
        />
    )
}

interface HistogramFilterProps
    extends Omit<FieldProps, 'onChange' | 'value' | 'minValue' | 'maxValue'> {
    aggregations: Aggregations
    filterKey: string
    aggregationKey: string
}

const HistogramFilter = ({
    aggregations,
    aggregationKey,
    filterKey,
    ...props
}: HistogramFilterProps) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const queryValue = query[filterKey] as `${number}:${number}` | undefined
    const value: Range = useMemo(() => {
        if (!queryValue) return
        const [min, max] = queryValue.split(':')
        return { min: parseInt(min, 10), max: parseInt(max, 10) }
    }, [queryValue])

    const buckets = useMemo<AggregationBucket[]>(() => {
        const filterBuckets = (aggregations, key) => {
            const filtered = aggregations?.filtered || aggregations
            return (
                filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0) ||
                []
            )
        }
        return filterBuckets(aggregations.data, aggregationKeys[aggregationKey])
    }, [aggregations.data, aggregationKey])

    const maxValue = buckets.reduce(
        (max, bucket) => Math.max(max, parseInt(`${bucket.key}`, 10)),
        1
    )

    const minValue = buckets.reduce(
        (min, bucket) => Math.min(min, parseInt(`${bucket.key}`, 10)),
        1
    )

    const handleChange = useCallback(
        ({ min, max }) => {
            push(
                changeQuery({
                    [filterKey]: `${min}:${max}`,
                })
            )
        },
        [filterKey, changeQuery, push]
    )

    if (!buckets.length) return null

    return (
        <HistogramField
            {...props}
            minValue={minValue}
            maxValue={maxValue}
            value={value}
            onChange={handleChange}
        />
    )
}

export default HistogramFilter
