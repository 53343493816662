import { t } from '@zupr/i18n'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'
import { Aggregations, Category, Filter, Variation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { formatPrice } from '@zupr/utils/price'
import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo } from 'react'
import sluggify from 'slug'
import '../../../../scss/react/filter/filters.scss'
import AreaContext from '../../../context/domain'
import { useIsMobile } from '../../../context/ux'
import PaginationSort from '../../../shared/components/pagination/sort'
import Trans from '../../../shared/components/trans'
import AutocompleteFilter, {
    RemoveAutocompleteFilter,
} from '../../../shared/filters/autocomplete'
import FilterBox from '../../../shared/filters/box'
import FilterCollapse from '../../../shared/filters/collapse'
import RemoveFilter, { RemoveKeys } from '../../../shared/filters/remove'
import FilterContainer from '../../components/search/filter/container'
import FilterLinks, { FilterLink } from '../../components/search/filter/link'
import Multiselect from '../../components/search/filter/multiselect'
import BucketselectFilter from './filters/bucketselect'
import ProductsCategories from './filters/category'
import Color from './filters/color'
import DiscountFilter from './filters/discount'
import FeatureFilters from './filters/features'
import Histogram, { HistogramMinMax } from './filters/histogram'
import HistogramSelect from './filters/histogram-select'
import ShopperLocationFilter, {
    RemoveShopperLocation,
} from './filters/shopper-location'
import StockFilters from './filters/stock'

const sortSizes = (buckets) => {
    return buckets.sort((a, b) => a.key >= b.key)
}

interface Props {
    aggregations: Aggregations
    products: List<Variation>
    categoryBreadcrumbs: Category[]
    filter: Filter
    categories: Category[]
    activeCategory: Category
    productsAtLocation?: boolean
    baseUrl?: string
    useSlugs?: boolean
}

const ProductsFilters = ({
    aggregations,
    products,
    categoryBreadcrumbs,
    filter,
    productsAtLocation,
    categories,
    activeCategory,
    baseUrl = '/producten',
    useSlugs = false,
}: Props) => {
    const { query } = useRouter()

    const { shoppingAreaSlug, themes } = useContext(AreaContext)
    const isMobile = useIsMobile()

    const { variables } = filter

    const price = variables[filterKeys.price]
    const priceValue = price && {
        min: parseInt(price.split(':')[0], 10),
        max: parseInt(price.split(':')[1], 10),
    }

    // if value is not false these items are removable in the filter
    const removeable = {
        ordering: query.ordering && t(query.ordering, 'filter'),
        deliverable: t('Deliver'),
        collectable: t('Click & Collect'),
        reservable: t('Reserve'),
        stock: t('In stock'),
    }

    const removeableCount = Object.keys(removeable)
        .filter((key) => key !== 'ordering')
        .filter((key) => !!query[key]).length

    const filterThemes = useCallback(
        (bucket) => bucket.key && themes?.includes(bucket.key),
        [themes]
    )

    const themeAggregations = useMemo(
        () =>
            (aggregations?.data &&
                !variables[filterKeys.theme] &&
                aggregations.data[aggregationKeys.themes]?.buckets?.filter(
                    filterThemes
                )) ||
            [],
        [aggregations, filterThemes, variables]
    )

    const data = aggregations.data || {}

    return (
        <FilterContainer
            resultCount={products?.count}
            filterCount={filter.filterCount + removeableCount}
            renderFilters={
                <div className="filters">
                    {filter.filterCount + removeableCount > 0 && (
                        <FilterBox
                            header={
                                <>
                                    <h4>
                                        <Trans label="Gekozen filters" />
                                    </h4>
                                    <RemoveKeys
                                        href={baseUrl}
                                        keys={[
                                            'ordering',
                                            ...Object.keys(filterKeys),
                                        ]}
                                    />
                                </>
                            }
                        >
                            {Object.keys(removeable)
                                .filter((key) => !!query[key])
                                .map((key) => (
                                    <RemoveFilter filterKey={key}>
                                        {removeable[key]}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.price] && (
                                <RemoveFilter filterKey="price">
                                    <Trans label="Price" />
                                    {` (${formatPrice(
                                        priceValue.min,
                                        'EUR',
                                        'NL'
                                    )} - ${formatPrice(
                                        priceValue.max,
                                        'EUR',
                                        'NL'
                                    )})`}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.color]
                                ?.split(',')
                                .map((color) => (
                                    <RemoveFilter
                                        key={color}
                                        filterKey="color"
                                        removeFromValue={color}
                                        value={variables[filterKeys.color]}
                                    >
                                        <Trans label={color} context="colors" />
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.size]
                                ?.split(',')
                                .map((size) => (
                                    <RemoveFilter
                                        key={size}
                                        filterKey="size"
                                        removeFromValue={size}
                                        value={variables[filterKeys.size]}
                                    >
                                        <Trans label="Size" />
                                        {': '}
                                        {size}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.brands] && (
                                <RemoveAutocompleteFilter
                                    url="fo/brand"
                                    filterKey="brands"
                                />
                            )}

                            {variables[filterKeys.locations] && (
                                <RemoveAutocompleteFilter
                                    url="fo/location"
                                    filterKey="locations"
                                />
                            )}

                            {variables[filterKeys.box] && (
                                <RemoveShopperLocation />
                            )}

                            {variables[filterKeys.demographic]
                                ?.split(',')
                                .map((demographic) => (
                                    <RemoveFilter
                                        key={demographic}
                                        filterKey="demographic"
                                        removeFromValue={demographic}
                                        value={
                                            variables[filterKeys.demographic]
                                        }
                                    >
                                        <Trans label={demographic} />
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.theme] && (
                                <RemoveFilter href={baseUrl} filterKey="theme">
                                    {t(variables[filterKeys.theme], 'themes')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.discount] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="discount"
                                >
                                    {t('Actieproducten')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.edition] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="edition"
                                >
                                    {t('edition')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.year_published] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="year_published"
                                >
                                    {t('year_published')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.promilage] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="promilage"
                                >
                                    {t('Alcohol percentage')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.minimum_players] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="minimum_players"
                                >
                                    {t('minimum_players')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.maximum_players] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="maximum_players"
                                >
                                    {t('maximum_players')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.mechanics] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="mechanics"
                                >
                                    {t('mechanics')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.series] && (
                                <RemoveFilter href={baseUrl} filterKey="series">
                                    {t('series')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.ptheme] && (
                                <RemoveFilter href={baseUrl} filterKey="ptheme">
                                    {t('theme')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.publisher] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="publisher"
                                >
                                    {t('publisher')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.publisher] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="edition"
                                >
                                    {t('edition')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.age] && (
                                <RemoveFilter href={baseUrl} filterKey="age">
                                    {t('minimum_recommended_age')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.play_time] && (
                                <RemoveFilter
                                    href={baseUrl}
                                    filterKey="play_time"
                                >
                                    {t('play_time')}
                                </RemoveFilter>
                            )}
                        </FilterBox>
                    )}
                    {isMobile && (
                        <FilterBox>
                            <PaginationSort
                                defaultOrdering="-product_locations.created"
                                choices={[
                                    'product_locations.price',
                                    '-product_locations.price',
                                    '-product_locations.created',
                                ]}
                            />
                        </FilterBox>
                    )}

                    <StockFilters query={query} />

                    <FeatureFilters
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {!productsAtLocation && (
                        <ShopperLocationFilter variables={variables} />
                    )}

                    <DiscountFilter
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {isMobile && (
                        <ProductsCategories
                            baseUrl={baseUrl}
                            useSlugs={useSlugs}
                            categories={categories}
                            activeCategory={activeCategory}
                            categoryBreadcrumbs={categoryBreadcrumbs}
                        />
                    )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.demographic] && (
                            <FilterCollapse header="Doelgroep">
                                <FilterLinks
                                    value={variables[filterKeys.demographic]}
                                    filterKey="demographic"
                                    aggregationKey={aggregationKeys.demographic}
                                    buckets={
                                        data[aggregationKeys.demographic]
                                            .buckets
                                    }
                                    formatLabel={(demographic) => (
                                        <Trans label={demographic} />
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    {/* No location filter on location products page */}
                    {!variables[filterKeys.location] && (
                        <AutocompleteFilter
                            url="fo/location"
                            label="Locations"
                            filterKey="locations"
                            initialLimit={0}
                            variables={{
                                shopping_areas: shoppingAreaSlug,
                            }}
                        />
                    )}

                    {themeAggregations.length >= 1 && (
                        <FilterCollapse header="Themes">
                            {themeAggregations.map((bucket) => {
                                const count =
                                    bucket['reverse.product_locations.themes']
                                        .doc_count

                                if (!useSlugs) {
                                    return (
                                        <FilterLink
                                            key={bucket.key}
                                            query={{ theme: bucket.key }}
                                            href={baseUrl}
                                            count={count}
                                        >
                                            {t(bucket.key, 'themes')}
                                        </FilterLink>
                                    )
                                }
                                return (
                                    <FilterLink
                                        key={bucket.key}
                                        href={`/${sluggify(
                                            t(bucket.key, 'themes')
                                        )}`}
                                        count={count}
                                    >
                                        {t(bucket.key, 'themes')}
                                    </FilterLink>
                                )
                            })}
                        </FilterCollapse>
                    )}

                    {filter.filterCount > 0 &&
                        aggregationKeys.size &&
                        data[aggregationKeys.size] && (
                            <FilterCollapse
                                startCollapsed={!variables[filterKeys.size]}
                                header="Sizes"
                            >
                                <Multiselect
                                    value={variables[filterKeys.size]}
                                    filterKey="size"
                                    aggregationKey={aggregationKeys.size}
                                    buckets={sortSizes(
                                        data[aggregationKeys.size].buckets
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.materials] && (
                            <FilterCollapse
                                startCollapsed={
                                    !variables[filterKeys.materials]
                                }
                                header="Materials"
                            >
                                <Multiselect
                                    value={variables[filterKeys.materials]}
                                    filterKey="materials"
                                    aggregationKey={aggregationKeys.materials}
                                    formatLabel={(label) =>
                                        t(label, 'material')
                                    }
                                    buckets={sortSizes(
                                        data[aggregationKeys.materials].buckets
                                    )}
                                />
                            </FilterCollapse>
                        )}

                    <AutocompleteFilter
                        url="fo/brand"
                        label="Brands"
                        filterKey="brands"
                        options={data[aggregationKeys.brand]?.buckets?.map(
                            ({ key, doc_count }) => ({
                                id: key,
                                count: doc_count,
                            })
                        )}
                        variables={
                            // if location is set as filter for products
                            // also use it for brands
                            (filter.variables[
                                'product_locations.location.id'
                            ] && {
                                'location.id':
                                    filter.variables[
                                        'product_locations.location.id'
                                    ],
                            }) ||
                            {}
                        }
                    />

                    {filter.filterCount > 0 && data[aggregationKeys.color] && (
                        <FilterCollapse
                            startCollapsed={!variables[filterKeys.color]}
                            header="Color"
                        >
                            <Color
                                value={variables[filterKeys.color]}
                                filterKey="color"
                                aggregationKey={aggregationKeys.color}
                                buckets={data[aggregationKeys.color].buckets}
                                formatLabel={(color) => (
                                    <Trans label={color} context="colors" />
                                )}
                            />
                        </FilterCollapse>
                    )}

                    {!!activeCategory && (
                        <>
                            <HistogramMinMax
                                header="Aantal spelers"
                                aggregations={aggregations}
                                minAggregationKey="minimum_players"
                                maxAggregationKey="maximum_players"
                                minFilterKey="minimum_players"
                                maxFilterKey="maximum_players"
                            />

                            <HistogramSelect
                                aggregations={aggregations}
                                filterKey="age"
                                aggregationKey="minimum_recommended_age_histogram"
                                header="minimum_recommended_age"
                                unit="jaar"
                            />

                            <HistogramSelect
                                aggregations={aggregations}
                                filterKey="play_time"
                                aggregationKey="play_time_histogram"
                                header="play_time"
                                unit="min"
                            />

                            <BucketselectFilter
                                label="year_published"
                                aggregations={aggregations}
                                filterKey="year_published"
                                aggregationKey="year_published"
                            />

                            <BucketselectFilter
                                label="edition"
                                aggregations={aggregations}
                                filterKey="edition"
                                aggregationKey="edition"
                            />

                            <BucketselectFilter
                                label="theme"
                                aggregations={aggregations}
                                filterKey="ptheme"
                                aggregationKey="theme"
                            />

                            <BucketselectFilter
                                label="publisher"
                                aggregations={aggregations}
                                filterKey="publisher"
                                aggregationKey="publisher"
                            />

                            <BucketselectFilter
                                label="series"
                                aggregations={aggregations}
                                filterKey="series"
                                aggregationKey="series"
                            />

                            <BucketselectFilter
                                label="manual_language"
                                aggregations={aggregations}
                                filterKey="languages"
                                aggregationKey="languages"
                            />

                            <BucketselectFilter
                                label="mechanics"
                                aggregations={aggregations}
                                filterKey="mechanics"
                                aggregationKey="mechanics"
                            />

                            <Histogram
                                aggregations={aggregations}
                                filterKey="promilage"
                                aggregationKey="promilage"
                                header="Alcohol percentage"
                                step={5}
                                factor={10}
                                unit="%"
                            />

                            <Histogram
                                aggregations={aggregations}
                                filterKey="pieces"
                                aggregationKey="number_of_pieces_histogram"
                                header="number_of_pieces"
                            />
                        </>
                    )}

                    <Histogram
                        aggregations={aggregations}
                        filterKey="price"
                        aggregationKey="price"
                        header="Price"
                        step={100}
                        factor={100}
                        unit="€"
                    />
                </div>
            }
        />
    )
}

export default ProductsFilters
