import { aggregationKeys } from '@zupr/next/helpers/products'
import { AggregationBucket, Aggregations } from '@zupr/types/fo'
import { useMemo } from 'react'

import MutltiselectFilter from '../../../../shared/filters/mulitselect'

interface Props {
    aggregations: Aggregations
    filterKey: string
    aggregationKey: string
    label: string
}

const BucketSelectFilter = ({
    filterKey,
    aggregations,
    aggregationKey,
    label,
}: Props) => {
    const buckets = useMemo<AggregationBucket[]>(() => {
        const filterBuckets = (aggregations, key) => {
            const filtered = aggregations?.filtered || aggregations
            return (
                filtered?.[key]?.buckets
                    .filter((bucket) => bucket.doc_count >= 0)
                    .sort((a, b) => a.key >= b.key) || []
            )
        }
        return filterBuckets(aggregations.data, aggregationKeys[aggregationKey])
    }, [aggregations.data, aggregationKey])

    if (!buckets.length) return null

    return (
        <MutltiselectFilter
            label={label}
            options={buckets.map((bucket) => ({
                label: `${bucket.key}`,
                isChecked: (query) => {
                    const value = query.get(filterKey)
                    return value?.includes(bucket.key.toString())
                },
                setChecked: (query) => {
                    const value = query.get(filterKey)?.split(',') || []
                    value.push(bucket.key.toString())
                    query.set(filterKey, value.join(','))
                    return query
                },
                unCheck: (query) => {
                    const value = query.get(filterKey)?.split(',') || []
                    query.set(
                        filterKey,
                        value
                            .filter((v) => v !== bucket.key.toString())
                            .join(',')
                    )
                    return query
                },
            }))}
        />
    )
}

export default BucketSelectFilter
