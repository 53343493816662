import { Brand, Product, ProductLocation } from '@zupr/types/fo'
import { formatSubtitle } from '@zupr/utils/product'
import { brandUrl, productUrl } from '@zupr/utils/url'
import Link from 'next/link'
import Share from '../components/share'

import '../../../scss/react/pages/location-product.scss'

interface Props {
    product: Product | ProductLocation['product']
    brand?: Brand
}

const ProductTitles = ({ product, brand }: Props) => (
    <div className="product-titles">
        <div>
            <h1>{product.title}</h1>

            <h2>
                {((product?.brand?.id || brand) && (
                    <Link href={brandUrl(brand || product.brand)}>
                        {formatSubtitle(product, brand)}
                    </Link>
                )) ||
                    formatSubtitle(product)}
            </h2>
        </div>
        <Share url={productUrl(product)} />
    </div>
)

export default ProductTitles
