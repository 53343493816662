import { aggregationKeys } from '@zupr/next/helpers/products'
import { AggregationBucket, Aggregations } from '@zupr/types/fo'
import { useMemo } from 'react'
import '../../../../../scss/react/filter/histogram.scss'
import FilterCollapse from '../../../../shared/filters/collapse'
import LinkChangeQuery from '../../../../shared/router/link-change-query'

interface HistogramFilterProps {
    header: string
    aggregations: Aggregations
    filterKey: string
    aggregationKey: string
    unit?: 'jaar' | 'min'
}

const HistogramSelect = ({
    aggregations,
    aggregationKey,
    filterKey,
    header,
    unit,
}: HistogramFilterProps) => {
    const buckets = useMemo<AggregationBucket[]>(() => {
        const filterBuckets = (aggregations, key) => {
            const filtered = aggregations?.filtered || aggregations
            return (
                filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0) ||
                []
            )
        }
        return filterBuckets(aggregations.data, aggregationKeys[aggregationKey])
    }, [aggregations.data, aggregationKey])

    const maxValue = buckets.reduce(
        (max, bucket) => Math.max(max, parseInt(`${bucket.key}`, 10)),
        1
    )

    if (!buckets.length || buckets.length === 1) return null

    return (
        <FilterCollapse header={header}>
            <div className="multiselect-filter">
                <ul>
                    {buckets.map((bucket) => (
                        <li key={bucket.key}>
                            <LinkChangeQuery
                                query={{
                                    [filterKey]: `${bucket.key}:${maxValue}`,
                                }}
                            >
                                {bucket.key}
                                {`+ ${unit}`}
                            </LinkChangeQuery>
                        </li>
                    ))}
                </ul>
            </div>
        </FilterCollapse>
    )
}

export default HistogramSelect
